import React from 'react'
import cover from './img/cover.jpg'

const VideoJS = () => {
     
    return (
    

                 <div className="fullscreen vjs-big-play-centered">
                      
            <video id="hostlagarto_video" class="video-js" preload="auto" poster={cover} data-setup='{}' controls playsInline>
            <source src={process.env.REACT_APP_M3U8} className="video-js fullscreen" type="application/x-mpegURL" />
            </video> 
           
                 </div>
                 
                
                     
         )
}

export default VideoJS;


import React, { useState, useEffect, useRef     } from 'react'
import { db, auth } from '../firebase'
import SendMessage from './SendMessage'

function Chat() {
    const scroll = useRef()
    
    const [messages, setMessages] = useState([]) 
    
    useEffect(() => {
        db.collection('messages').orderBy('createdAt').limitToLast(30).onSnapshot(snapshot => {
            setMessages(snapshot.docs.map(doc => doc.data()))
        })
    }, [])
    
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  
    useEffect(() => {
      scrollToBottom()
    }, [messages]);
  
    return (
        <div >
            <div className="msgs" >
                {messages.map(({ id, text, photoURL, displayName, uid }) => (
                    <div>
                        <div scroll={scroll} key={id} className={`msg ${uid === auth.currentUser.uid ? 'sent' : 'received'}`}>
                            <img src={photoURL} alt="" />
                            <div className="tex">
                            <p className="name">{displayName}</p>
                            <p>{text}</p>
                            </div>
                        </div>
                        
                    </div>
                ))}
            </div>
            
            <SendMessage/>
            <div ref={messagesEndRef}></div>
        </div>
    )
}

export default Chat

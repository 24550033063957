import * as React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
    
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>

      <BootstrapDialog 
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
       
      >
        <BootstrapDialogTitle id="customized-dialog-title"  style={{ background:'#112146' }}>
        <Typography variant="h6" style={{ fontWeight: "800", flexGrow: 1, textAlign: "center", alignItems: "center", display: 'flex', justifyContent: "left" }}>
          <img src={process.env.REACT_APP_DERECHOS_LOGO} alt="Logo" style={{ width: '300', height: '100', borderRadius: '0'}}/>
          </Typography>
        </BootstrapDialogTitle >
        <DialogContent dividers  style={{ background:'#112146' }}>
          <Typography gutterBottom>
            Si quieres contratar un servicio de calidad StreamingTV, StreamingRadio, Hosting, Dominio, Servidores
            Puedes visitarnos en nuestro sitio <Link target="_blank" style={{ color:'#d49e06', fontWeight: "800"}} href={process.env.REACT_APP_DERECHOS_LINK} rel="noreferrer" underline="none">
            {process.env.REACT_APP_DERECHOS_NAME}
            </Link>
          </Typography>
        </DialogContent>
        <DialogActions  style={{ background:'#112146' }}>
          <Button autoFocus href={process.env.REACT_APP_DERECHOS_LINK} target="_blank" rel="noreferrer" onClick={handleClose}>
            Ir al sitio
          </Button>
          <Button autoFocus onClick={handleClose}>
            Salir
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
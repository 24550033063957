import React from 'react'
import firebase from 'firebase'
import { auth } from '../firebase.js'
import { Button } from '@material-ui/core'
import { FcGoogle } from "react-icons/fc";

function SignIn() {
    function signInWithGoogle() {
        const provider = new firebase.auth.GoogleAuthProvider()
        auth.signInWithPopup(provider)
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '83vh', alignItems: 'center' }} className="center">
                       <Button startIcon={<FcGoogle  />} style={{ color:'black', background:'white',padding: '15px', fontSize: '15px', borderRadius: '0', fontWeight: '600' }} onClick={signInWithGoogle}>
        Ingresa con Google</Button>
        </div>
    )
}

export default SignIn


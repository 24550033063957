import './App.css';
import Navbar from './components/Navbar.js'

function App() {
  return (
    
    <>
    
    <div className="App"><Navbar /></div>
    </>
  );
}

export default App;

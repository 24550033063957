import React from 'react'


function Limpio() {
    return (
        <div>
            
        </div>
    )
}

export default Limpio

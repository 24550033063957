import React, { useState } from 'react'
import { db, auth } from '../firebase'
import firebase from 'firebase'
import { Input, Button } from '@material-ui/core'

function SendMessage({ scroll }) {
    
    const [msg, setMsg ] = useState('')
    
    async function sendMessage(e) {
        e.preventDefault();
        const { uid, displayName, photoURL } = auth.currentUser
        const trimmedMessage = msg.trim();
        if (trimmedMessage) {
            // Add new message in Firestore
        await db.collection('messages').add({
            text: trimmedMessage,
            photoURL,
            uid,
            displayName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        // Clear input field
        setMsg('');
        //
    }
    }
    
    return (
        <div>
            <form onSubmit={sendMessage}>
                <div className="sendMsg">
                    <Input style={{ width: '78%', fontSize: '15px', fontWeight: '550', marginLeft: '5px', marginBottom: '-3px' }} inputProps={{ maxLength: 150 }} placeholder='Escribe...' type="text" value={msg} onChange={e => setMsg(e.target.value)} />
                    <Button style={{ width: '18%', fontSize: '15px', fontWeight: '550', margin: '4px 5% -13px 5%', maxWidth: '200px'}} type="submit" disabled={!setMsg}>Enviar</Button>
                </div>
            </form>
        </div>
    )
}

export default SendMessage

import * as React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
export default function NestedList() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const url = window.location.href

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader style={{ fontWeight: "600", textAlign: "left", justifyContent: "left" }} component="div" id="nested-list-subheader">
          Redes Sociales
        </ListSubheader>
      }
    >
      <ListItem button component="a" href={process.env.REACT_APP_FACEBOOK} target="_blank" rel="noreferrer">
        <ListItemIcon>
          <FacebookIcon />
        </ListItemIcon>
        <ListItemText primary="Facebook" />
      </ListItem>
      <ListItem button component="a" href={process.env.REACT_APP_TWITTER} target="_blank" rel="noreferrer">
        <ListItemIcon>
          <TwitterIcon />
        </ListItemIcon>
        <ListItemText primary="Twitter" />
      </ListItem>
      <ListItem button component="a" href={process.env.REACT_APP_INSTAGRAM} target="_blank" rel="noreferrer">
        <ListItemIcon>
          <InstagramIcon />
        </ListItemIcon>
        <ListItemText primary="Instagram" />
      </ListItem>
      <ListSubheader style={{ fontWeight: "600", textAlign: "left", justifyContent: "left" }} component="div" id="nested-list-subheader">
          Apoyanos
        </ListSubheader>
      <ListItem Button onClick={handleClick}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText primary="Compartir" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4 }}>
          <FacebookShareButton style={{  flexGrow: 0, alignItems: "center", display: 'flex' }} url={url}> 
            <ListItemIcon>
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="Facebook" />
            </FacebookShareButton>
          </ListItem>
          <ListItem button sx={{ pl: 4 }}>
          <TwitterShareButton style={{  flexGrow: 0, alignItems: "center", display: 'flex' }} url={url}>
            <ListItemIcon>
              <TwitterIcon />
            </ListItemIcon>
            <ListItemText primary="Twitter" />
            </TwitterShareButton>
          </ListItem>
          <ListItem button sx={{ pl: 4 }}>
          <WhatsappShareButton style={{  flexGrow: 0, alignItems: "center", display: 'flex' }} url={url}>
            <ListItemIcon>
              <WhatsAppIcon />
            </ListItemIcon>
            <ListItemText primary="WhatsApp" />
            </WhatsappShareButton>
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}

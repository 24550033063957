import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCZKenTdsdnKYR5A64R4muw_T73KV16yEk",
    authDomain: "radio-7d6eb.firebaseapp.com",
    projectId: "radio-7d6eb",
    storageBucket: "radio-7d6eb.appspot.com",
    messagingSenderId: "111209656183",
    appId: "1:111209656183:web:5f2ea7ad0b31effae47b72",
    measurementId: "G-3P1Y4S6R37"
})

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db, auth }